@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
  .btn {
    @apply uppercase font-semibold py-2 px-4 hover:bg-red-700 bg-red-600;
  }
  .wholepage {
    @apply bg-black text-gray-200 font-sans text-sm;
  }
  .formstyle {
    @apply text-gray-200;
  }
  .halfbox {
    @apply bg-gray-900 text-center p-6 border-4 border-dashed border-red-500;
  }
  .textinput {
    @apply bg-black mx-2 mb-6 rounded-sm border border-gray-400 focus:border-white focus:ring-2 focus:ring-offset-gray-900 focus:ring-offset-4 focus:ring-red-600;
  }
  .maxtokens {
    @apply text-xs my-1 mx-auto text-right pr-7 -mt-2;
  }
  .radiocheck {
    @apply rounded text-red-500 m-0 p-0 mx-2 align-text-bottom focus:ring-offset-0 focus:ring-red-700 border-none;
  }
  .tablecell {
    @apply px-2 py-2;
  }
  .loader {
    @apply border-4 rounded-full p-2 animate-spin  h-5 w-5 mx-auto border-red-700 border-dashed mt-0;
  }
  .tooltip {
    @apply opacity-0 absolute p-2 bg-black shadow-lg ml-2 text-sm transition-opacity duration-300 invisible;
  }
  .has-tooltip:hover .tooltip {
    @apply opacity-100 z-50 visible;
  }
  .has-tooltip {
    @apply inline;
  }
}

